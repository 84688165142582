import React, { useEffect, useRef, useState } from 'react';
import styles from './MainMenu.module.css';
import { useNavigate, useParams } from 'react-router-dom';

export default function MainMenu({ category }) {
  const navigate = useNavigate();
  const params = useParams();
  const menuRef = useRef(null);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  useEffect(() => {
    const checkScrollPosition = () => {
      if (menuRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } =
          menuRef.current;
        setShowLeftArrow(scrollLeft > 0); // Show left arrow if scrolled past the beginning
        setShowRightArrow(scrollLeft + clientWidth < scrollWidth); // Show right arrow if not fully scrolled to the end
      }
    };

    checkScrollPosition();

    const menu = menuRef.current;
    if (menu) {
      menu.addEventListener('scroll', checkScrollPosition);
    }

    return () => {
      if (menu) {
        menu.removeEventListener('scroll', checkScrollPosition);
      }
    };
  }, []);

  const scrollMenu = (direction) => {
    const scrollAmount = 150; // Scroll by 150px
    if (menuRef.current) {
      menuRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={styles.menuContainer}>
      {showLeftArrow && (
        <div
          className={styles.arrowLeft}
          onClick={() => scrollMenu('left')}
        >
          &#9664; {/* Left arrow */}
        </div>
      )}
      <div className={styles.menuWrapper}>
        <ul className={styles.menu} ref={menuRef}>
          {category ? (
            category.map((e) => (
              <li
                key={e.id}
                className={`${styles.MainMenu} ${
                  params.id === e.categoryName ? styles.selected : ''
                }`}
                onClick={() =>
                  navigate(`/category/${e.categoryName}`)
                }
              >
                <p>{e.categoryName}</p>
              </li>
            ))
          ) : (
            <p>loading</p>
          )}
        </ul>
      </div>
      {showRightArrow && (
        <div
          className={styles.arrowRight}
          onClick={() => scrollMenu('right')}
        >
          &#9654;
        </div>
      )}
    </div>
  );
}
