import React from 'react';
import ContactInfo from '../Components/Contact/ContactInfo';
const Contact = () => {
  return (
    <div>
      <ContactInfo/>
    </div>
  );
}

export default Contact;
