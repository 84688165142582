import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { PasswordResetAPI } from '../request/api';
import { Form, Input, Button , message} from 'antd';


export default function PasswordReset() {

  const { resetToken } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const email = query.get('email');

  const resetHandle = async (values) => {

    const newPassword = values.newPassword;

    const data = {
      resetToken,
      newPassword,
      email,
    }

    const response = await PasswordResetAPI(data);

    if (response?.errCode === 0) {
      message.success('Password reset success');
      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
    } else {
      message.error('Password reset failed');
    }
  };

  return (
    <div>
      <Form
        name="password_reset"
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
        style={{
          maxWidth: 450,
        }}
        initialValues={{
          token: resetToken,
        }}
        onFinish={resetHandle}
        autoComplete="off"
      >
        <Form.Item
          name="token"
          hidden={true}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <input type="hidden" name="token" value={resetToken} />
        </Form.Item>
        <Form.Item
          labelCol={{
            xs: {
              span: 24,
            },
            sm: {
              span: 8,
            },
          }}
          wrapperCol={{
            xs: {
              span: 24,
            },
            sm: {
              span: 16,
            },
          }}
          name="newPassword"
          label="New Password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          labelCol={{
            xs: {
              span: 24,
            },
            sm: {
              span: 8,
            },
          }}
          wrapperCol={{
            xs: {
              span: 24,
            },
            sm: {
              span: 16,
            },
          }}
          name="confirm"
          label="Confirm Password"
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  getFieldValue('newPassword') === value
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    'The new password that you entered do not match!'
                  )
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button htmlType="submit" type="primary">
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

