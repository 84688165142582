import React, { useState } from 'react';
import {
  Upload,
  Form,
  Input,
  Button,
  notification,
  message,
} from 'antd';
import { Category, UploadImage } from '../../request/api';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const AddNewCategory = () => {
  const [loading, setLoading] = useState(false);
  const [categoryImageUrl, setCategoryImageUrl] = useState();
  const [productImageUrl, setProductImageUrl] = useState();
  const [colorGuideImageUrl, setColorGuideImageUrl] = useState();
  const [categoryImageName, setCategoryImageName] = useState();
  const [productImageName, setProductImageName] = useState();
  const [colorGuideImageName, setColorGuideImageName] = useState();
  const [fileList, setFileList] = useState([]);

  notification.config({
    placement: 'topLeft',
    bottom: 50,
    duration: 3,
    rtl: true,
  });

  const [form] = Form.useForm();

  // Function to handle form submission
  const handleSubmit = async (data) => {

    const dataToDeliver = {
      categoryName: data.categoryName.trim(),
      categoryImageUrl: categoryImageUrl,
      productImageUrl: productImageUrl,
      colorGuideImageUrl: colorGuideImageUrl,
    };

    const response = await Category(dataToDeliver);

    if (response) {
      notification.success({
        message: response.message,
      });
      setTimeout(() => {
        setLoading(false);
        setCategoryImageUrl();
        setProductImageUrl();
        setColorGuideImageUrl();
        form.resetFields();
      }, 2000);
    } else {
      notification.error({
        message: response.message,
      });
    }
  };

  const handleChange = (info, setImageUrl, setImageName) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setFileList((prevFileList) => [
        ...prevFileList,
        {
          uid: info.file.uid,
          name: info.file.name,
          status: 'done',
          url: info.file.response.data.url,
          thumbUrl: info.file.response.data.url,
        },
      ]);
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
      setImageName(info.file.name);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file');
      return false;
    }

    const isLt800KB = file.size / 1024 < 800;
    if (!isLt800KB) {
      message.error('Image must be smaller than 800KB');
      return false;
    }

    return isJpgOrPng && isLt800KB;
  };

  const customRequest = async (
    { file, onSuccess, onError },
    setImageUrl,
    setImageName
  ) => {
    try {
      const response = await UploadImage(file);

      if (response && response.errCode === 0) {
        setImageUrl(response.data.url);
        setImageName(response.data.originName);
        onSuccess();
        setFileList((prevFileList) => [
          ...prevFileList,
          {
            ...file,
            thumbUrl: response.data.url,
            url: response.data.url,
          },
        ]);
      } else {
        notification.error({
          message: response.error,
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  const uploadButton = (
    <>
      <button
        style={{
          border: 0,
          background: 'none',
        }}
        type="button"
      >
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div
          style={{
            marginTop: 8,
          }}
        >
          Upload
        </div>
      </button>
    </>
  );

  const nonUploadButton = (imageUrl, imageName) => (
    <div>
      <img
        src={imageUrl}
        alt="avatar"
        style={{
          width: '100%',
        }}
      />
      {imageName ? (
        <span style={{ color: 'blue' }}>{imageName}</span>
      ) : null}
    </div>
  );

  return (
    <div className="adminSubWindow">
      <Form form={form} onFinish={handleSubmit} layout="horizontal">
        <Form.Item
          label="Category Name"
          name="categoryName"
          rules={[
            {
              required: true,
              message: 'Please enter the Category Name',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Category Image"
          name="categoryImage"
          rules={[
            {
              required: true,
              message: 'Please upload the Category image',
            },
          ]}
        >
          <Upload
            name="categoryImage"
            className="avatar-uploader"
            listType="picture-card"
            customRequest={(options) =>
              customRequest(
                options,
                setCategoryImageUrl,
                setCategoryImageName
              )
            }
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(
                info,
                setCategoryImageUrl,
                setCategoryImageName
              )
            }
            fileList={fileList}
          >
            {categoryImageUrl
              ? nonUploadButton(categoryImageUrl, categoryImageName)
              : uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item
          label="Product Image"
          name="productImage"
        >
          <Upload
            name="productImage"
            className="avatar-uploader"
            listType="picture-card"
            customRequest={(options) =>
              customRequest(
                options,
                setProductImageUrl,
                setProductImageName
              )
            }
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(
                info,
                setProductImageUrl,
                setProductImageName
              )
            }
            fileList={fileList}
          >
            {productImageUrl
              ? nonUploadButton(productImageUrl, productImageName)
              : uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item
          label="Color Guide Image"
          name="colorGuideImage"
        >
          <Upload
            name="colorGuideImage"
            className="avatar-uploader"
            listType="picture-card"
            customRequest={(options) =>
              customRequest(
                options,
                setColorGuideImageUrl,
                setColorGuideImageName
              )
            }
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={(info) =>
              handleChange(
                info,
                setColorGuideImageUrl,
                setColorGuideImageName
              )
            }
            fileList={fileList}
          >
            {colorGuideImageUrl
              ? nonUploadButton(
                  colorGuideImageUrl,
                  colorGuideImageName
                )
              : uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Category
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddNewCategory;
